'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import DefaultConfig from '../configuration/DefaultConfig';
import ThemeColorBuilder from './ThemeColorBuilder';
import Helper from '../utils/Helper';
// TODO: make instance specific #multiple-instances
var Styles = {
    renderDefaultStyles: function (pluginConfiguration, layerId) {
        sxQuery('#ss360-default-css').remove();
        // eslint-disable-next-line
        var css = require('../../../sass/siteSearch/main.scss'); // load the default css // FIXME: indeed eslint issue?
        sxQuery('head').append("<style id='ss360-default-css' type=\"text/css\">" + css + "</style>");
        var styleSettings = pluginConfiguration.style;
        var mobileLayout = pluginConfiguration.layout.mobile;
        var desktopLayout = pluginConfiguration.layout.desktop;
        // Grid
        var innerStyle = '';
        if (mobileLayout.type === 'grid' || desktopLayout.type === 'grid') {
            var overridenGridStyles = [];
            var colSm = Math.min(mobileLayout.gridColsSm || 1, 10);
            var colMd = Math.min(mobileLayout.gridColsMd || 2, 10);
            var colLg = Math.min(desktopLayout.gridColsLg || 3, 10);
            var colXl = Math.min(desktopLayout.gridColsXl || 4, 10);
            // eslint-disable-next-line max-len
            var extraFlex_1 = '@media (#M_QUERY#){.ss360-grid--#SZ#{-ms-grid-columns:#COLS#;grid-template-columns:#COLS#;}.ss360-grid--#SZ# .ss360-suggests__header{min-height: #MIN_HEIGHT#}}';
            var buildColEntry = function (mediaQuery, size, cols) {
                var minHeight = cols === 1 ? '0' : '54px';
                return extraFlex_1.replace('#M_QUERY#', mediaQuery).replace(/#SZ#/g, size).replace(/#COLS#/g, new Array(cols + 1).join('1fr ').trim()).replace('#MIN_HEIGHT#', minHeight);
            };
            if (mobileLayout.type === 'grid') {
                var isMdOverriden = false;
                if (colMd !== 2) {
                    isMdOverriden = true;
                    overridenGridStyles.push(buildColEntry('max-width: 991px', 'sm', colMd));
                }
                if (colSm !== 1 || isMdOverriden) {
                    overridenGridStyles.push(buildColEntry('max-width: 767px', 'sm', colSm));
                }
            }
            if (desktopLayout.type === 'grid') {
                var isLgOverriden = false;
                if (colLg !== 3) {
                    isLgOverriden = true;
                    overridenGridStyles.push(buildColEntry('min-width: 992px', 'lg', colLg));
                }
                if (colXl !== 4 || isLgOverriden) {
                    overridenGridStyles.push(buildColEntry('min-width: 1200px', 'lg', colXl));
                }
            }
            innerStyle += overridenGridStyles.join('');
        }
        // theme color
        if (styleSettings.themeColor !== DefaultConfig.style.themeColor || styleSettings.accentColor !== DefaultConfig.style.accentColor) {
            var themeColor = styleSettings.themeColor;
            if (pluginConfiguration.filters.enabled === true) {
                var rgbAccentColor = Helper.colorToRgb(styleSettings.accentColor);
                if (rgbAccentColor !== null) {
                    innerStyle += ".ss360-slider__handle {box-shadow: 0.5px 0.5px 2px 1px rgba(" + rgbAccentColor.r + ", " + rgbAccentColor.g + ", " + rgbAccentColor.b + ", .32)}";
                }
            }
            sxQuery('body').append("<style id=\"ss360-theme-color\">" + ThemeColorBuilder('ss360', themeColor, styleSettings.accentColor, "#" + layerId).build() + "</style>");
        }
        // tabbed navigation
        var navigationLayout = pluginConfiguration.layout.navigation;
        // @ts-ignore
        if (parseInt(navigationLayout.tabSpacingPx, 10) !== DefaultConfig.layout.navigation.tabSpacingPx) {
            // @ts-ignore
            var tabSpacingPx = parseInt(navigationLayout.tabSpacingPx, 10);
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--left li:not(:last-of-type) button{margin-bottom: " + tabSpacingPx + "px}";
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--top li:not(:last-of-type) button{margin-right: " + tabSpacingPx + "px}";
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--top li.ss360-nav__menu-item--active+li{padding-left: " + (tabSpacingPx + 1) + "px}";
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--left li.ss360-nav__menu-item--active+li{padding-top: " + (tabSpacingPx + 1) + "px}";
        }
        // @ts-ignore
        if (parseInt(navigationLayout.borderRadiusPx, 10) !== DefaultConfig.layout.navigation.borderRadiusPx) {
            // @ts-ignore
            var borderRadiusPx = parseInt(navigationLayout.borderRadiusPx, 10);
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--top li button{border-top-left-radius:" + borderRadiusPx + "px;border-top-right-radius:" + borderRadiusPx + "px}";
            innerStyle += "nav.ss360-nav--tabs.ss360-nav--left li button{border-bottom-left-radius:" + borderRadiusPx + "px;border-top-left-radius:" + borderRadiusPx + "px}";
        }
        if (styleSettings.additionalCss) {
            innerStyle += styleSettings.additionalCss;
        }
        if (pluginConfiguration.queryTerm.highlightColor !== DefaultConfig.queryTerm.highlightColor) {
            innerStyle += ".ss360-search-term-highlight{background-color: " + pluginConfiguration.queryTerm.highlightColor + "}";
        }
        if (innerStyle !== '') {
            sxQuery('#ss360-custom-css').remove();
            sxQuery('head').append("<style id=\"ss360-custom-css\">" + innerStyle + "</style>");
        }
    }
};
export default Styles;
