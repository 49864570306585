'use strict';
import Helper from '../utils/Helper';
var THEME_COLOR_NAMES = ['c', 'c--hover:hover', 'c--hover:focus'];
var BORDER_COLOR_NAMES = ['b', 'b--hover:hover', 'b--hover:focus', 'b--focus:focus'];
var BACKGROUND_COLOR_NAMES = ['bg', 'bg--hover:hover', 'bg--hover:focus', 'bg--focus:focus'];
var darkenColorHex = function (hex, percent) {
    try {
        if (hex.length === 4) {
            hex = "#" + Array(3).join(hex[1]) + Array(3).join(hex[2]) + Array(3).join(hex[3]);
        }
        var rgb = Helper.colorToRgb(hex);
        if (rgb === null) {
            return rgb;
        }
        var r = rgb.r, g = rgb.g, b = rgb.b;
        r = Math.round(Math.max(0, Math.min((r * ((100 - percent) / 100)), 255)));
        g = Math.round(Math.max(0, Math.min((g * ((100 - percent) / 100)), 255)));
        b = Math.round(Math.max(0, Math.min((b * ((100 - percent) / 100)), 255)));
        var rString = r.toString(16);
        var gString = g.toString(16);
        var bString = b.toString(16);
        if (rString.length === 1)
            rString = "0" + rString;
        if (gString.length === 1)
            gString = "0" + gString;
        if (bString.length === 1)
            bString = "0" + bString;
        return "#" + rString + gString + bString;
    }
    catch (err) {
        return hex;
    }
};
var ThemeColorBuilder = function (prefix, themeColor, accentColor, id) {
    var buildClassName = function (arr, isThemeColor, idString) {
        if (isThemeColor === void 0) { isThemeColor = true; }
        return arr.map(function (n) { return idString + "." + prefix + "-" + (isThemeColor ? 't' : 'a') + "c-" + n; }).join(',');
    };
    var build = function (arr, content, isThemeColor, idString) {
        if (isThemeColor === void 0) { isThemeColor = true; }
        return buildClassName(arr, isThemeColor, idString) + "{" + content + ":" + (isThemeColor ? themeColor : accentColor) + "}";
    };
    var buildThemeColor = function (idString) { return build(THEME_COLOR_NAMES, 'color', true, idString); };
    var buildBorderColor = function (idString) { return build(BORDER_COLOR_NAMES, 'border-color', true, idString); };
    var buildBackgroundColor = function (idString) { return build(BACKGROUND_COLOR_NAMES, 'background-color', true, idString); };
    var buildAccentColor = function (idString) { return (accentColor !== undefined ? build(THEME_COLOR_NAMES, 'color', false, idString) : ''); };
    var buildAccentBorderColor = function (idString) { return (accentColor !== undefined ? build(BORDER_COLOR_NAMES, 'border-color', false, idString) : ''); };
    var buildAccentBackgroundColor = function (idString) { return (accentColor !== undefined ? build(BACKGROUND_COLOR_NAMES, 'background-color', false, idString) : ''); };
    var batchBuild = function (idName) {
        var idString = idName !== undefined ? idName + " " : '';
        var darkAccentHex = accentColor !== undefined ? darkenColorHex(accentColor, 16) : '';
        var darkAccentBg = accentColor !== undefined ? idString + ".ss360-acd-bg--hover:hover," + idString + ".ss360-acd-bg--hover:focus{background-color:" + darkAccentHex + "}" : '';
        var darkAccentBorder = accentColor !== undefined ? idString + ".ss360-acd-b--hover:hover," + idString + ".ss360-acd-b--hover:focus{border-color:" + darkAccentHex + "}" : '';
        // eslint-disable-next-line max-len
        return "" + buildThemeColor(idString) + buildBorderColor(idString) + buildBackgroundColor(idString) + buildAccentColor(idString) + buildAccentBorderColor(idString) + buildAccentBackgroundColor(idString) + darkAccentBg + darkAccentBorder;
    };
    return {
        build: function () {
            return "" + batchBuild() + batchBuild(id);
        }
    };
};
export default ThemeColorBuilder;
